export const formatIban = (iban: string) => {
  return (
    iban
      // eslint-disable-next-line unicorn/prefer-spread
      .split("")
      .map((character, index) => {
        if (index && index % 4 === 0) return ` ${character}`;

        return character;
      })
      .join("")
  );
};

export const formatPhoneNumber = (value: string) => {
  return value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
};

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
