export const useEnvironment = () => {
  const {
    public: { environment },
  } = useRuntimeConfig();

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isE2E: process.client && !!window.Cypress,
    isDev: environment === "development",
    isProduction: environment === "production",
    isStaging: environment === "staging",
  };
};
