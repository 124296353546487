export default defineNuxtRouteMiddleware(async (to) => {
  const { me, isAdmin, isAuthenticated } = useCurrentUser();
  const { $analytics } = useNuxtApp();

  const currentUser = useEnrichedCurrentUser();

  if (me.value) {
    await currentUser.ensureIsLoaded();

    if (import.meta.client && useState("identify_id").value !== me.value.id) {
      $analytics.identify(me.value.id, {
        email: me.value.email,
        signed_up: true,
        organization:
          currentUser.me.value?.organization_membership?.organization?.slug,
      });
      useState("identify_id").value = me.value.id;
    }
  } else {
    currentUser.resetState();
  }

  if (isAuthenticated.value && to.name === "auth-login") {
    return await navigateTo((to.query.redirect as string) ?? "/");
  }

  if (to.meta.requiresAuthentication && !isAuthenticated.value) {
    return await navigateTo({
      name: "auth-login",
      hash: "#signin",
      query: {
        redirect: to.fullPath,
      },
    });
  }

  if (to.meta.requiresAdminAccess && !isAdmin.value) {
    /*
    Let's force refresh the session here to ensure that admin user is loaded in any case
    It's usefull in case of document generation page where an admin user is spawned on the server side
    and passed directly to Pupetter through cookies
     */
    await useDatabaseClient().auth.setSession({
      access_token: useCookie("authentication-access-token").value!,
      refresh_token: useCookie("authentication-refresh-token").value!,
    });

    if (!isAdmin.value) {
      throw createError({
        statusCode: 404,
        fatal: true,
      });
    }
  }
});
