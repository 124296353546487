export const useOrganizationMemberProfile = () => {
  const { me } = useEnrichedCurrentUser();

  const profile = computed(() => me.value?.organization_membership);
  const hasOrganizationMembership = computed(() => !!profile.value);

  const hasPendingRequestForSession = (sessionId: number) => {
    return profile.value?.requests.some(
      (request) => request.session_id === sessionId,
    );
  };

  function hasRole(role: DatabaseEnum<"organization_member_role">) {
    return profile.value?.role === role;
  }

  return {
    hasRole,
    profile,
    hasOrganizationMembership,
    hasPendingRequestForSession,
  };
};
