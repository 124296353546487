<template>
  <app-carousel :item-gap="0" :item-width="250" :items="list">
    <template #item="{ item }">
      <div class="h-full p-2">
        <course-card class="h-full" :course="item" @click="onCardClick(item)" />
      </div>
    </template>
  </app-carousel>
</template>

<script lang="ts" setup>
import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";
import {
  toAnalyticsCourseView,
  type TrackingPlanModel,
} from "~~/model/analytics.model";

const properties = defineProps<{
  list: CourseCardView[];
  id: TrackingPlanModel["course_clicked"]["origin"];
}>();

const { $analytics } = useNuxtApp();

const onCardClick = (course: CourseCardView) =>
  $analytics.track("course_clicked", {
    origin: properties.id,
    course: toAnalyticsCourseView(course),
  });
</script>
