export default defineNuxtRouteMiddleware(async (to) => {
  const isRestrictedAccessFeatureEnabled = useFeatureFlag("restricted_access");
  const hasAccessAlreadyBeenGranted = useState("granted_access", () => false);

  if (!isRestrictedAccessFeatureEnabled || hasAccessAlreadyBeenGranted.value)
    return;

  const isAuthorized = await $fetch("/api/restricted-access/status", {
    headers: useRequestHeaders(["cookie"]),
  });

  hasAccessAlreadyBeenGranted.value = isAuthorized;

  if (isAuthorized && to.name === "restricted-access") {
    return navigateTo("/");
  }

  if (!isAuthorized && to.name !== "restricted-access") {
    return navigateTo({ name: "restricted-access" });
  }
});
