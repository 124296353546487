<template>
  <div class="relative flex items-center gap-4">
    <client-only v-if="displayControls">
      <app-carousel-control
        :class="{
          'absolute -top-4 right-9 -translate-y-full':
            controlsPosition === 'top-right',
          'relative top-8': controlsPosition === 'sides',
        }"
        hover-icon="ph:arrow-circle-left-fill"
        icon="ph:arrow-circle-left"
        :label="$t('app.cta.previous')"
        @click="prev"
      />
    </client-only>

    <ul
      ref="list"
      class="no-scrollbar relative flex snap-x snap-mandatory gap-[--itemGap] overflow-x-auto scroll-smooth"
      :style="{
        '--itemGap': `${itemGap * 4}px`,
      }"
    >
      <li
        v-for="(item, index) in items"
        :key="item.id"
        class="shrink-0 basis-[--itemWidth] snap-start"
        :data-cy-carousel-item="item.id"
        :style="{
          '--itemWidth': `${itemWidth}px`,
        }"
      >
        <slot name="item" v-bind="{ item, index }" />
      </li>
    </ul>

    <client-only v-if="displayControls">
      <app-carousel-control
        :class="{
          'absolute -top-4 right-0 -translate-y-full':
            controlsPosition === 'top-right',
          'relative top-8': controlsPosition === 'sides',
        }"
        hover-icon="ph:arrow-circle-right-fill"
        icon="ph:arrow-circle-right"
        :label="$t('app.cta.next')"
        @click="next"
      />
    </client-only>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="T extends Record<string, any> & { id: string | number }"
>
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";
import { useCarouselControls } from "~/shared/carousel/composables/carousel-controls.hook";

const properties = withDefaults(
  defineProps<{
    items: T[];
    itemWidth: number;
    itemGap?: number;
    controlsPosition?: "top-right" | "sides";
  }>(),
  {
    title: undefined,
    itemGap: 4,
    controlsPosition: "top-right",
  },
);

const listElement = templateRef<HTMLUListElement>("list");
const { displayControls, prev, next } = useCarouselControls(listElement);

provide<CarouselProvidedState>(CarouselProvideKey, {
  itemWidth: properties.itemWidth,
});
</script>
