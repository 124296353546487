<template>
  <video
    ref="video"
    autoplay
    :controls="!preview"
    controlslist="nodownload"
    disablePictureInPicture
    height="1080"
    :loop="preview"
    :muted="preview"
    playsinline
    preload="metadata"
    :src="src"
    width="1920"
    @timeupdate="onTimeUpdate"
  />
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    src: string;
    preview?: boolean;
    previewDuration?: number;
  }>(),
  {
    previewDuration: 4,
  },
);

const emit = defineEmits<{
  (
    event: "timeupdate",
    payload: { currentTime: number; totalTime: number },
  ): void;
}>();

const videoElement = templateRef<HTMLVideoElement>("video");

const onTimeUpdate = () => {
  if (!videoElement.value) return;

  if (!properties.preview) {
    return emit("timeupdate", {
      currentTime: videoElement.value.currentTime,
      totalTime: videoElement.value.duration,
    });
  }

  if (videoElement.value.currentTime >= properties.previewDuration) {
    videoElement.value.currentTime = 0;
  }
};

watch(
  () => properties.preview,
  () => {
    if (!videoElement.value) return;

    videoElement.value.currentTime = 0;
  },
);
</script>
