<template>
  <span class="inline-flex flex-wrap gap-2 align-middle">
    <app-image
      v-for="company in companies"
      :key="company.id!"
      :alt="`${company.name}`"
      class="object-contain"
      :class="{
        'rounded-sm bg-white': variant !== 'flat',
      }"
      :height="height"
      :src="company.logo_url!"
      :style="style"
    />
  </span>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    companies: DatabaseTable<"companies">[];
    height?: number;
    variant?: "flat" | "plain";
    maxWidth?: string | number;
  }>(),
  {
    variant: "plain",
    height: 20,
    maxWidth: undefined,
  },
);

const style = computed(() => {
  return {
    height: `${properties.height}px`,
    maxWidth:
      typeof properties.maxWidth === "string"
        ? properties.maxWidth
        : `${properties.height * 5}px`,
    padding:
      properties.variant === "plain" ? `${properties.height / 6}px` : undefined,
  };
});
</script>
