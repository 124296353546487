import type { MaybeRef } from "@vueuse/core";

export const useCloudinaryVideo = (
  url: MaybeRef<string | null>,
  options?: {
    width?: number;
    height?: number;
  },
) => {
  const {
    public: {
      cloudinary: { baseVideoUrl },
    },
  } = useRuntimeConfig();
  return computed(() => {
    const value = unref(url);

    if (!value) return;

    const parameters = new Map<string, string>([["q", "auto"]]);

    if (options?.width) {
      parameters.set("w", options.width.toString());
      parameters.set("c", "fill");
    }

    if (options?.height) {
      parameters.set("h", options.height.toString());
      parameters.set("c", "fill");
    }

    const stringifiedParameters = [...parameters.entries()]
      .map(([key, value]) => `${key}_${value}`)
      .join(",");

    return value.replace(
      baseVideoUrl,
      `${baseVideoUrl}${stringifiedParameters}/`,
    );
  });
};
