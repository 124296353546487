import type { Ref } from "vue";

export const useCarouselControls = (wrapper: Ref<HTMLElement | null>) => {
  const isVisible = ref(false);

  whenever(useElementVisibility(wrapper), () => {
    isVisible.value = true;
  });

  const wrapperIsVisible = (
    _wrapper: typeof wrapper,
  ): _wrapper is Ref<HTMLElement> => isVisible.value;

  const currentItemIndex = ref(0);

  const displayControls = computed(() => {
    if (hasTouchSupport() || !wrapperIsVisible(wrapper)) return false;

    return wrapper.value.scrollWidth > wrapper.value.clientWidth;
  });

  const prev = () => {
    if (!wrapperIsVisible(wrapper)) return;

    const hasReachedScrollStart = wrapper.value.scrollLeft < 10;

    if (hasReachedScrollStart) return;

    const scrollTarget =
      wrapper.value?.querySelector<HTMLLIElement>(
        `:scope > *:nth-child(${currentItemIndex.value--})`,
      )?.offsetLeft ?? 0;

    wrapper.value.scrollLeft = scrollTarget;
  };

  const next = () => {
    if (!wrapperIsVisible(wrapper)) return;

    const hasReachedScrollEnd =
      wrapper.value.scrollLeft >=
      wrapper.value.scrollWidth - wrapper.value.clientWidth;

    if (hasReachedScrollEnd) return;

    const scrollTarget =
      wrapper.value.querySelector<HTMLLIElement>(
        `:scope > *:nth-child(${currentItemIndex.value++ + 2})`,
      )?.offsetLeft ?? 0;

    wrapper.value.scrollLeft = scrollTarget;
  };

  return {
    displayControls,
    prev,
    next,
  };
};
