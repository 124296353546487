export default defineNuxtPlugin((nuxtApp) => {
  /*
  Ignore ts-error for now as nuxt-schema-org package does not seem to be totally typed
   */

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  nuxtApp.hooks.hook("schema-org:meta", (meta) => {
    meta.host = nuxtApp.$host;
  });
});
