import { dialogEventHook } from "./dialog.hook";

export const useActiveDialog = createGlobalState(() => {
  const id = ref<string | undefined>();
  const binding = ref<Record<string, unknown> | undefined>();

  dialogEventHook.open.on((event) => {
    id.value = event.id;
    binding.value = event.binding;
  });

  dialogEventHook.close.on(() => {
    id.value = undefined;
    binding.value = undefined;
  });

  return {
    id,
    binding: readonly(binding),
  };
});
