declare module "#app" {
  interface NuxtApp {
    $host: string;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const host =
    nuxtApp.ssrContext?.event.req.headers.host ?? window.location.host;
  const protocol = host.includes("localhost") ? "http://" : "https://";

  return {
    provide: {
      host: `${protocol}${host}`,
    },
  };
});
