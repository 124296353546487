const dialogRegistry = {
  registrationRequestDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration-request/components/registration-request-dialog.vue"
      ),
  ),
  postRegistrationRequestDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration-request/components/post-registration-request-dialog.vue"
      ),
  ),
  onboardingDialog: defineAsyncComponent(
    () =>
      import("~/business-areas/onboarding/components/onboarding-dialog.vue"),
  ),
  postRegistrationLinkDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration-link/components/registration-link-post-registration-dialog.vue"
      ),
  ),
} satisfies Record<string, ReturnType<typeof defineAsyncComponent>>;

export { dialogRegistry };
