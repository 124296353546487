<template>
  <button
    class="group rounded-full outline-none ring-current transition-all focus-visible:ring-2 active:scale-90"
    :title="label"
  >
    <app-icon class="group-hover:!hidden" :icon="icon" :size="iconSize" />
    <app-icon
      class="!hidden group-hover:!inline"
      :icon="hoverIcon"
      :size="iconSize"
    />
    <span class="sr-only">{{ label }}</span>
  </button>
</template>

<script lang="ts" setup>
import type { CarouselControlProps } from "~/shared/carousel/carousel.model";

defineProps<CarouselControlProps>();

const iconSize = 32;
</script>
