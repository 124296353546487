export const useLearnerProfile = () => {
  const { me } = useEnrichedCurrentUser();

  const registeredSessions = computed(() => me.value?.sessions ?? []);
  const certificates = computed(() => me.value?.certificates ?? []);

  const isRegisteredToCourseSession = (
    sessionId: DatabaseTable<"course_sessions">["id"],
  ) => {
    return registeredSessions.value.some((session) => session.id === sessionId);
  };

  return {
    registeredSessions,
    certificates,
    isRegisteredToCourseSession,
  };
};
