import type { I18nMessage } from "~/core/i18n/i18n.model";

export type CarouselControlProps = {
  icon: string;
  hoverIcon: string;
  label: I18nMessage;
};

export type CarouselProvidedState = {
  itemWidth: number;
};

export const CarouselProvideKey = Symbol("Carousel state");
