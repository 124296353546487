import type { AnalyticsClient } from "@pollen/core/client/composables/analytics.hook";

import type { TrackingPlanModel } from "~~/model/analytics.model";

declare module "#app" {
  interface NuxtApp {
    $analytics: AnalyticsClient<TrackingPlanModel>;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: {
      segment: { apiKey },
    },
  } = useRuntimeConfig();

  setupAnalyticDirectives<TrackingPlanModel>(nuxtApp);

  return {
    provide: {
      analytics: getAnalyticsClient<TrackingPlanModel>(apiKey),
    },
  };
});
